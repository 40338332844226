@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.root,
#root,
#docs-root {
  --primary: #4db5ff;
  --primary-variant: rgba(77, 181, 255, 0.4);
  --bg: #1f1f30;
  --bgVariant: #2c2c6c;
  --header-CTA: #4db5ff;
  --light: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;
  --navBg: rgba(0, 0, 0, 0.3);

  --meBgImg: linear-gradient(45deg, transparent, #4db5ff, transparent);
}
.meBgImg {
  background: linear-gradient(45deg, transparent, #4db5ff, transparent);
}
section {
  margin-top: 5rem;
}
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
html {
  scroll-behavior: smoothe;
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: white;
  background-color: #1f1f30;
  background-image: url("/src/assets/bg-texture.png");
}
.meBgImg1 {
  background: linear-gradient(294deg, #4db6ff, transparent);
}
.header__socials {
  text-decoration: column;
  gap: 0.8rem;
  align-items: center;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--primary);
}
.borderr {
  border: 1px solid var(--primary);
}
.me1 {
  background: linear-gradiant(red, transparent);
  background-color: red;
  width: 15rem;
  height: 30rem;
  position: absolute;
  right: 0.1rem;
  margin-top: 2rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}

.mee {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.fix-img-md {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fix-img-lg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 30rem;
}

nav a.active {
  background: var(--primary);
  color: var(--bgVariant);
}

.slide-top {
  -webkit-animation: slide-top 2000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) NaNs
    infinite alternate-reverse both;
  animation: slide-top 2000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) NaNs infinite
    alternate-reverse both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
} 

.parallax {
  /* The image used */

  /* Set a specific height */
   min-height: 500px;

  /* Create the parallax scrolling effect */
   background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
 } 
